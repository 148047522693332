import React from "react";
import { Helmet } from 'react-helmet';
import { Link, graphql } from "gatsby";
import * as BS from "react-bootstrap";
import 'bootstrap/dist/css/bootstrap.min.css';
import { BreadcrumbLite } from '../../components/common/BreadcrumbLite'
import InvestorReports from '../../components/featured/InvestorReports'
import styled from 'styled-components'
const RightCol = styled.div`
  text-align: right;
  @media only screen and (max-width: 768px) {
    text-align: left;
  }
`

const corporatevc = (props) => {


  const renderPlayer = (edge, index) => {

    return (
      <section>
        <BS.Card key={index} style={{ marginTop: 5, marginBottom: 5 }}><BS.Card.Body fluid>
          <BS.Row>
            <BS.Col sm={6}>
              <div>
                < Link to={`/investor/${edge.node.name.toLowerCase().replace(/ /g, "-")}/`}>{edge.node.name} </Link>
              </div>
            </BS.Col>
            <BS.Col sm={6}><RightCol>
              investments : {edge.node.count}
            </RightCol>
            </BS.Col>
          </BS.Row>
        </BS.Card.Body>
        </BS.Card>
      </section>
    )
  }

  return (

    <section>
      <Helmet>
        <html lang="en" />
        <title>Corporate VCs Ranked By Unicorn Alert</title>
        <meta name="title" content="Corporate VCs Ranked By Unicorn Alert"></meta>
        <meta name="description" content='Get the latest updates from startups, investors and fundraising in your realtime Unicorn Alert dashboard.' />
      </Helmet>
      <BS.Container style={{ paddingLeft: 0, paddingRight: 0, maxWidth: 1400 }}>
      <BreadcrumbLite>
        <BS.Breadcrumb.Item> <Link to={`/`}>Home</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item> <Link to={`/investor/`}>Investors</Link></BS.Breadcrumb.Item>
        <BS.Breadcrumb.Item active>Corporate VCs</BS.Breadcrumb.Item>
      </BreadcrumbLite>

      <BS.Row style={{display: 'block'}}>
          <BS.Col sm={12}>
            <h1>Corporate VCs - Venture Capital Investors</h1>
            <h2 style={{color: '#34383b', fontWeight: 300, marginBottom: 25}}>Corporate Venture capital funds ranked by number of investments</h2>
            </BS.Col>
        </BS.Row>

        <BS.Row>
          <BS.Col sm={8}>
     
            {props.data.allInvestor.edges.map(renderPlayer)}
          </BS.Col>
          <BS.Col sm={4}>


            <InvestorReports />

          </BS.Col>
        </BS.Row>

      </BS.Container>

    </section >

  );
}

export const query = graphql`
{
  allInvestor(sort: {order: DESC, fields: count}, filter: {category: {eq: "Corporate VC"}, count: {gt: 0}}) {
    edges {
      node {
        count
        name
        id
        type
        website
        location
        category
      }
    }
  }
}
`

export default corporatevc
